import { useContext, useState } from "react"
import { AppContext } from "./context/AppContext"
import Menu from "./Menu"
import ReactDOM from 'react-dom';
import BackDrop from "./UI/BackDrop";


const Layout = props => {

    const app = useContext(AppContext)
    const [showMenu,setShowMenu] = useState(false);

     return <>
        {app.isLoggedIn && <Menu />}

        {showMenu && <MenuOverlay hideMenu={() => setShowMenu(false)} />}
        <div className="app">
            {app.isLoggedIn &&<header>
                <div>
                    <button onClick={ () => setShowMenu( !showMenu )} className='toggleMenuButton'>...</button>
                </div>
                <div>
                    <small> { app?.user?.name } </small>
                    {app.isLoggedIn && <button className="link" onClick={app.logout}>Log out</button>} 
                </div>
            </header> }
            <main>
                {props.children}
            </main>
            <footer>stage:{ process.env.REACT_APP_ENV } <b className='ml10'>nb<span style={{color:'blue'}}>_</span></b></footer>
        </div>
    </>
}

const MenuOverlay = props => {
    const hideMenu = () =>{
        props.hideMenu()
    }
    return <>
        <BackDrop onClick={hideMenu} />
        <MenuWrap {...props} />
    </>   
}

const MenuWrap = props => {
    return ReactDOM.createPortal(<div className='menu-overlay'><Menu /></div>,document.getElementById('menu-hook'))
}
export default Layout