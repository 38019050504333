import { useEffect, useState } from "react"
import DownloadButton from "../../../shared/components/DownloadButton";
import fetchRequest from "../../../shared/fetchRequest"
import { Button } from "../../../shared/UI/Dumbs"
import Page from "../../../shared/UI/Page"
import ImportCatCuentas from "../components/ImportCatCuentas";
import ImportPptos from "../components/ImportPptos";
import ImportSaldos from "../components/ImportSaldos";

let dollarUSLocale = Intl.NumberFormat('en-US',{
    style: "currency",
    currency: "USD",
});


const  Rpt5 = () => {

    const [rows,setRows] = useState([])

    const [disabled,setDisabled] = useState(true)
    const [month,setMonth] = useState('01')
    const [year,setYear] = useState('2022')
    const [company,setCompany] = useState('')
    const companies = [
        {
            name:"SMETA",
            dbKey:"001385"
        },
        {
            name:"Dineek",
            dbKey:"002174"
        }
    ]


    const getStructure = async() => {
        setRows([])
        const {rows} = await fetchRequest('GET',`rpt5/structure/${company}/${year}/${month}`)
        setRows(rows)
    }

    useEffect( () => {
        setDisabled( !company || !year || !month )
    },[company,year, month])

    return <Page>
        <Page.Header>
            <div>
                <h3>Rpt5 - Reporte de Variación</h3>
                <div className="mb10">
                    <ImportCatCuentas company={company} year={year} onComplete={ e => alert('Catálogo Actualizado')} />
                    <ImportSaldos company={company} year={year} month={month} onComplete={ e => alert('Saldos Actualizados')} />
                    <ImportPptos company={company} year={year} onComplete={ e => alert('Presupuestos Actualizados')} />
                </div>
                <div className="mb10">
                    <select value={year} onChange={ e => setYear(e.target.value)}>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                    </select>
                    <select value={month} onChange={ e => setMonth(e.target.value)}>
                        <option value="01">Enero</option>
                        <option value="02">Febrero</option>
                    </select>
                    <select value={company} onChange={ e => setCompany(e.target.value)}>
                        <option value="">Choose Company</option>
                        {companies.map( company => <option key={company.dbKey} value={company.dbKey}>{company.name}</option>)}
                    </select>
                    
                    <Button disabled={disabled} onClick={getStructure}>Previsualizar</Button>
                    <DownloadButton disabled={disabled} url={`rpt5/excel-report/${company}/${year}/${month}`} label="Descargar reporte" />
                </div>


                </div>
        </Page.Header>
        <Page.Body className="scrollable">
            {!!rows.length && <table className="card">
                <thead>
                    <tr>
                        <th rowSpan={2}>Cuenta</th>
                        <th rowSpan={2}>Descripción</th>
                        <th colSpan={4}>Enero</th>
                        {!!(month > '01') && <th colSpan={4}>Febrero</th>}
                    </tr>
                    <tr>
                        <th>Real</th>
                        <th>Plan</th>
                        <th>Variación</th>
                        <th>%</th>
                        {!!(month > '01') && <th>Real</th>}
                        {!!(month > '01') && <th>Plan</th>}
                        {!!(month > '01') && <th>Variación</th>}
                        {!!(month > '01') && <th>%</th>}
                    </tr>
                </thead>

                <tbody>
                    {rows?.map( s => <tr key={s.cuenta} style={{  
                        background : s.nivel === 1 ? '#23297a' : (s.nivel === 2 ? '#3740bd' : (s.nivel === 3 ? '#abb0ff' :'transparent') ),
                        color : (s.nivel < 3) ? 'white' : 'black',
                        fontWeight : (s.nivel === 1) ? 'bold' : 'normal',
                    }}>
                        <td className="noWrap">{s.cuenta}</td>
                        <td className="noWrap">{s.descripcion}</td>
                        <td className="right">{dollarUSLocale.format(s.enero || 0)}</td>
                        <td className="right">{dollarUSLocale.format(s.pptoEnero || 0)}</td>
                        <td className="right">{dollarUSLocale.format(s.varEnero || 0) }</td>
                        <td className="right">{s.porcEnero ? s.porcEnero+'%' : ''}</td>

                        {!!(month > '01') &&  <td className="right">{dollarUSLocale.format(s.febrero || 0)}</td>}
                        {!!(month > '01') &&  <td className="right">{dollarUSLocale.format(s.pptoFebrero || 0)}</td>}
                        {!!(month > '01') &&  <td className="right">{dollarUSLocale.format(s.varFebrero || 0) }</td>}
                        {!!(month > '01') &&  <td className="right">{s.porcFebrero ? s.porcFebrero+'%' : ''}</td>}
                    </tr>)}
                </tbody>
            </table>}

        </Page.Body>
    </Page>
}
export default Rpt5