import { useState } from "react";
import fetchRequest from "../../../shared/fetchRequest";
import { Button } from "../../../shared/UI/Dumbs"

 const companies = []
 companies["001385"] = "SMETA";
 companies["002174"] = "Dineek";

const ImportSaldos = ({company,year,month, onComplete}) => {
    const [step,setStep] = useState(0);
    const [packages,setPackages] = useState(0);
    const getSaldos = async() => {
        const response = await fetchRequest('GET',`rpt5/saldos/inspect/${company}/${year}/${month}`)
        console.log(response)
        if(response.status === 'empty'){
            startImportation()
        }else{
            setStep(response?.importation?.step)
            setPackages(response?.importation?.packages)
            if(response?.importation?.end){
                startImportation()
            }else{
                importNextStep(response.importation)
            }
            console.log('found',response.importation)
        }
    }
    const startImportation = async() => {
        const response = await fetchRequest('GET',`rpt5/saldos/start-importation/${company}/${year}/${month}`)
        importNextStep(response.importation)
        setStep(response?.importation?.step)
        setPackages(response?.importation?.packages)
        console.log('started',response.importation)
    }

    const importNextStep = async(importation) => {
        const response = await fetchRequest('GET',`rpt5/saldos/importation-step/${company}/${year}/${month}/${importation.step}`)
        setStep(response?.importation?.step)
        setPackages(response?.importation?.packages)
        if(response?.importation?.step < response?.importation?.packages){
            importNextStep(response.importation)
        }else{
            onComplete()
        }
        console.log('step' + importation.step,response)
    }

    return <>
    <Button onClick={getSaldos} disabled={(!company || !month)}>Obtener Saldos {company && companies[company]} {year}{month}</Button>
    {step !== packages && <span className="ml10">{step} of {packages}</span>}
    </>
}

export default ImportSaldos