import { Route, Routes } from "react-router-dom"
import Pptos from "./pages/Pptos"
import PptoPreview from "./pages/PptoPreview"

const PptosRoutes = () => {
    return <Routes>
        <Route path="/presupuestos" element={<Pptos />} />
        <Route path="/presupuestos/source/:id" element={<PptoPreview />} />
    </Routes>
}
export default PptosRoutes