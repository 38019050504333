
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import fetchRequest from "../../../shared/fetchRequest";

const User = () => {

    const {id} = useParams()

    const navigate = useNavigate();

    const [user, setUser] = useState({
        name:'',
        email:''
    })

    const updateUser = async e =>{
        e.preventDefault()
        await fetchRequest('PUT',`users/${user.id}`,user)
        navigate('/usuarios')
    }

    const getUser = useCallback(async() => {
        if(!id) return false;
        const {user} = await fetchRequest('GET','users/' + id);
        setUser(user)
    },[id])

    useEffect( () => {
        getUser()
    },[getUser])

    return <div className="page">
        <header>
            <h3>User</h3>
            <Link to="/usuarios"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            {user?.id &&  <form onSubmit={updateUser} className="card">
                <label>
                    Nombre
                    <input placeholder="Name" value={user.name} onChange={ e => setUser( state => ({...state, name:e.target.value}))} />
                </label>
                <label>
                    Correo electrónico
                    <input placeholder="Email" type="email" value={user.email} onChange={ e => setUser( state => ({...state, email:e.target.value}))} />
                </label>
                <label style={{flexDirection:'row', alignItems: 'center'}}>
                    Administrador
                    <input type="checkbox" checked={user.isAdmin} onChange={ e => setUser( state => ({...state, isAdmin:!user.isAdmin}))} />
                </label>
                <label style={{flexDirection:'row', alignItems: 'center'}}>
                    Developer
                    <input type="checkbox" checked={user.isDev} onChange={ e => setUser( state => ({...state, isDev:!user.isDev}))} />
                </label>
    
                
                
                <button type="submit">Actualizar usuario</button>
            </form>}

        </main>
    </div>
}

export default User;