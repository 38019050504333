import { useEffect, useState } from "react"
import DownloadButton from "../../../shared/components/DownloadButton";
import SelectEmpresa from "../../../shared/components/SelectEmpresa";
import fetchRequest from "../../../shared/fetchRequest"
import { Button } from "../../../shared/UI/Dumbs"
import Page from "../../../shared/UI/Page"
import Pusher from 'pusher-js';
import moment from "moment";
import SelectAnio from "../../../shared/components/SelectAnio";
import SelectMes from "../../../shared/components/SelectMes";
//Pusher.logToConsole = true;
var pusher;
let waiting;
let dollarUSLocale = Intl.NumberFormat('en-US',{
    style: "currency",
    currency: "USD",
});


const  Rpt2 = () => {

    const [NexusStatus,setNexusStatus] = useState('')
    const [query,setQuery] = useState(`SELECT * FROM users`)

    // const pingNexus = async () => {
    //     const response = await fetchRequest('POST',`rpt1/nexus/query`,{query})
    //     console.log(response)
    // }
    const [rows,setRows] = useState([])

    const [disabled,setDisabled] = useState(true)
    const [calculating,setCalculating] = useState(false)
    const [month,setMonth] = useState('01')
    const [year,setYear] = useState('2022')

    const [company,setCompany] = useState('')

    const calcular = async() => {
        if(calculating) return false
        setCalculating(true)
        const response = await fetchRequest('GET',`rpt2/calcular/sierra-nexus/${company}/${year}/${month}`)
        console.log(response)
        setCalculating(false)
    }
    const getCFDNexus = async() => {
        setRows([])
        const {rows} = await fetchRequest('GET',`rpt2/cfd-nexus/${company}/${year}/${month}`)
        setRows(rows)
    }

    useEffect( () => {
        setDisabled( !company || !year || !month )
    },[company,year, month])

    //NEXUS
    const [nexusWaiting,setNexusWaiting] = useState(false)
    const [nexusBusy,setNexusBusy] = useState(false)

    const getNexusData = async () => {
        if(nexusBusy) return false
        setNexusBusy(true)
        setNexusStatus(' ( Esperando respuesta de Nexus... )')
        const response = await fetchRequest('POST',`rpt2/nexus/${company}/${year}/${month}`,{
            token:localStorage.token || ''
        })
        console.log('response',response)
        //setNexusBusy(false)
    }

    const noResponse = () => {
        clearInterval(waiting);
        setNexusWaiting(false)
        alert('No se ha recibido respuesta del Complemento Nexus\nRevisar en el servidor de Nexus que la aplicación se encuentre abierta')

    }
    const pingNexus = async() => {
        setNexusWaiting(true)
        waiting = setInterval(noResponse,5000);
        await fetchRequest('POST','utils/nexus/ping',{
          token:localStorage.token || ''
        })
    }

    const ProcessNexusData = async(ev) => {
        setNexusStatus(' ( Procesando data... )')
        const response = await fetchRequest('GET',`rpt2/import-process/${ev}`)
        console.log(response)
        setNexusStatus('')
        setNexusBusy(false)
    }

    useEffect(() => {
    
        pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER
        });
        var channel = pusher.subscribe('nexus-channel');
    
        channel.bind('pong-event', async function(data) {
          console.log('Pong response received')
          setNexusWaiting(false)
          clearInterval(waiting);
          alert("Complemento Nexus Activo")
          console.log(data)
        });
        channel.bind('status-event', async function(data) {
          console.log('Status event received')
          console.log(data)
          setNexusStatus(' ( Respuesta de Nexus Recibida! )')
          if(data.message === 'data extracted'){
            ProcessNexusData(data.ev)
          }else{
            setNexusStatus('')
            setNexusBusy(false)
            console.log(data)
          }
          
          
        });
    
        return () => {
            //pusher.unsubscribe('nexus-channel');
            pusher.disconnect()
            pusher = null
        };
      }, []);

    return <Page>
        <Page.Header>
            <div>
                <h3>Rpt2 - Reporte comparativo Nexus vs Sierra</h3>
                <Button disabled={disabled || nexusBusy} onClick={getNexusData}>Get Nexus Data {NexusStatus}</Button>
                <Button disabled={nexusWaiting} onClick={pingNexus}>Ping Nexus {nexusWaiting && "( esperando respuesta...)"}</Button>
                {false && <div className="mb10">
                    <textarea value={query} onChange={ e => setQuery( e.target.value )} />
                    <button onClick={pingNexus}>Ping Nexus</button>
                </div>}
                <div className="mb10">
          
                    <SelectAnio value={year} onChange={setYear}/>
                    
                    <SelectMes value={month} onChange={setMonth}/>

                    <SelectEmpresa value={company} onChange={setCompany} />
                    
                    <Button disabled={disabled || calculating} onClick={calcular}>Calcular</Button>
                    <Button disabled={disabled} onClick={getCFDNexus}>Previsualizar</Button>
                    <DownloadButton disabled={disabled} url={`rpt2/excel-report/${company}/${year}/${month}`} label="Descargar reporte" />
                </div>


                </div>
        </Page.Header>
        <Page.Body className="scrollable">
            {!!rows.length && <table className="card">
                <thead>
                    <tr>
                        <th></th>
                        <th>UUID</th>
                        <th colSpan={2}>Fecha</th>
                        <th colSpan={2}>Razon Social</th>
                        <th colSpan={2}>RFC</th>
                        <th colSpan={2}>Total</th>
                        <th colSpan={1}>Descripción</th>
                        
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th >Sierra</th>
                        <th >Nexus</th>
                        
                        <th >Sierra</th>
                        <th >Nexus</th>

                        <th >Sierra</th>
                        <th >Nexus</th>

                        <th >Sierra</th>
                        <th >Nexus</th>

                        <th >Sierra</th>
                        
                    </tr>
                </thead>

{ /*
      sierra_uuid:row.uuid,
            sierra_fecha:row.fecha,
            sierra_factura:row.factura,
            sierra_clave:row.clave,
            sierra_cta:row.cta,
            sierra_razonSocial:row.razonSocial,
            sierra_rfc:row.rfc,
            sierra_debe:row.debe,
            sierra_haber:row.haber,
            sierra_descripcion:row.descripcion,
            sierra:true
*/}
      
                <tbody>
                    {rows?.map( s => <tr key={s.id} >
                        <td className="noWrap">{ (!s.sierra || !s.nexus) && 'Solo '+ (s.sierra ? 'Sierra' : 'Nexus')}</td>
                        <td className="noWrap">{s.sierra ? s.sierra_uuid : s.nexus_uuid}</td>

                        <td className="noWrap">{s.sierra_fecha}</td>
                        <td className="noWrap">{s.nexus_fecha ? moment(s.nexus_fecha).format('D/MM/Y') : ''}</td>

                        <td className="noWrap">{s.sierra_razonSocial}</td>
                        <td className="noWrap">{s.nexus_razonSocial}</td>
                        <td className="noWrap">{s.sierra_rfc}</td>
                        <td className="noWrap">{s.nexus_rfc}</td>

                        <td className="right">{dollarUSLocale.format(s.sierra_total || 0)}</td>
                        <td className="right">{dollarUSLocale.format(s.nexus_total || 0)}</td>

                        <td className="noWrap">{s.sierra_descripcion}</td>
                        

                    </tr>)}
                </tbody>
            </table>}

        </Page.Body>
    </Page>
}
export default Rpt2