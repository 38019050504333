import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import fetchRequest from "../../../shared/fetchRequest"
import { Button } from "../../../shared/UI/Dumbs";
import Page from "../../../shared/UI/Page"

let dollarUSLocale = Intl.NumberFormat('en-US',{
    style: "currency",
    currency: "USD",
});

const PptoPreview = () => {

    const {id} = useParams()
    const [src,setSrc] = useState()
    const [rows,setRows] = useState([])

    const getPpto = useCallback ( async () => {
        const {src,rows} = await fetchRequest('GET','utils/ppto/'+id)
        setSrc(src)
        setRows(rows)
    },[id])

    const importToSierra = async () => {
        if(!window.confirm("Se sustituira el contenido en el sistema de Sierra")) return false;
        await fetchRequest('PUT','utils/ppto/sierra-import/'+id)
    }

    useEffect( () => {
        getPpto()
    },[getPpto])
    return <Page>
        <Page.Header>
            <div style={{flex:1}}>

                <div style={{display:"flex", flex:1,
                    alignItems: "center",
                    justifyContent: "space-between"
            }}>
                    <h3>Presupuesto {src?.empresa?.name} "{src?.name}"</h3> <Button link="/presupuestos">volver</Button>
                </div>
                
                <div className="mb10"><Button onClick={importToSierra}>Importar a Sierra</Button>
                    <small>
                        <ul>
                            <li>Solo se actualizaran los valores definidos, en caso de requerir eliminar el presupuesto de alguna cuenta, se deberá pasar el valor "0"</li>
                            <li>Solo se importaran valores con una cuenta definida, valores sin cuenta se ignoraran</li>
                            <li>Solo se importaran cuentas a 4o nivel</li>
                            <li>El sistema permite la carga de multiples meses</li>
                            <li>Solo se actualizaran los meses definidos</li>
                            <li>Los valores numericos se redondearan a dos decimales</li>
                            <li>Solo se actualizaran cuentas existentes enm Sierra</li>
                        </ul>
                    </small>
                </div>
            </div>
        </Page.Header>
        <Page.Body className="scrollable">
            <table>
                <thead>
                    <tr>
                        <td>CUENTA</td>
                        <td>ENERO</td>
                        <td>FEBRERO</td>
                        <td>MARZO</td>
                        <td>ABRIL</td>
                        <td>MAYO</td>
                        <td>JUNIO</td>
                        <td>JULIO</td>
                        <td>AGOSTO</td>
                        <td>SEPTIEMBRE</td>
                        <td>OCTUBRE</td>
                        <td>NOVIEMBRE</td>
                        <td>DICIEMBRE</td>
                        <td>ANUAL</td>
                    </tr>
                </thead>
                <tbody>
                    {rows.map( row =><tr key={row.id}>
                        <td className="noWrap">{row.cuenta}</td>
                        <td className="right">{row.enero && dollarUSLocale.format(row.enero)}</td>
                        <td className="right">{row.febrero && dollarUSLocale.format(row.febrero)}</td>
                        <td className="right">{row.marzo && dollarUSLocale.format(row.marzo)}</td>
                        <td className="right">{row.abril && dollarUSLocale.format(row.abril)}</td>
                        <td className="right">{row.mayo && dollarUSLocale.format(row.mayo)}</td>
                        <td className="right">{row.junio && dollarUSLocale.format(row.junio)}</td>
                        <td className="right">{row.julio && dollarUSLocale.format(row.julio)}</td>
                        <td className="right">{row.agosto && dollarUSLocale.format(row.agosto)}</td>
                        <td className="right">{row.septiembre && dollarUSLocale.format(row.septiembre)}</td>
                        <td className="right">{row.octubre && dollarUSLocale.format(row.octubre)}</td>
                        <td className="right">{row.noviembre && dollarUSLocale.format(row.noviembre)}</td>
                        <td className="right">{row.diciembre && dollarUSLocale.format(row.diciembre)}</td>
                        <td className="right">{row.anual && dollarUSLocale.format(row.anual)}</td>
                    </tr>)}
                </tbody>
            </table>

        </Page.Body>
    </Page>
}
export default PptoPreview