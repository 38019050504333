const fetchRequest = async (method, path, body = {}, headers = {}) => {
    let defaultHeaders = {
        'Content-Type': 'application/json;charset=utf-8',
    }
    if(localStorage.token) {
        defaultHeaders = { ...defaultHeaders, "Authorization" :'Bearer ' + localStorage.token };
    }
    if(['GET'].includes(method)){
        body = undefined
    }
    try{
        const fetchResponse = await fetch(process.env.REACT_APP_API_ENDPOINT + path,{
            method,
            headers:{...defaultHeaders,...headers},
            body:JSON.stringify(body),
        })
        if(fetchResponse.ok){
            return await fetchResponse.json()
        }
        throw new Error((await fetchResponse.json())?.message)
    }catch(er){
        throw new Error(er)
    }
        
}
export default fetchRequest