import {BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import './App.scss';
import { useCallback, useState } from 'react';
import { AppContext, setToken, unsetToken, setUser, unsetUser } from './shared/context/AppContext';

import LoginPage from './modules/auth/pages/LoginPage';
//import RegisterPage from './modules/auth/pages/RegisterPage';

import Glossary from './modules/glossary/pages/Glossary';
import CreateGlossaryEntry from './modules/glossary/pages/CreateGlossaryEntry';
import GlossaryEntry from './modules/glossary/pages/GlossaryEntry';

import Main from './modules/main/Main';
import Layout from './shared/Layout';
import PptosRoutes from './modules/pptos/PptosRoutes';
import CFDsRoutes from './modules/cfd/cfdRoutes';
import NexusRoutes from './modules/nexus/NexusRoutes';
import Rpt1Routes from './modules/rpt1/Rpt1Routes';
import Rpt2Routes from './modules/rpt2/Rpt2Routes';
import Rpt5Routes from './modules/rpt5/Rpt5Routes';
import UserRoutes from './modules/users/UserRoutes';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState((localStorage.getItem("token")));
  const [user, setUserState] = useState(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")));
  
  const login = useCallback( (data) => {
    setIsLoggedIn(true);
    setUser(data.user);
    setUserState(data.user)
    setToken(data.token);
  },[])

  const logout = useCallback( () => {
    setIsLoggedIn(false);
    unsetUser();
    setUserState(null)
    unsetToken();
  },[])

  return <AppContext.Provider value={{ isLoggedIn, login, logout, user }}>
    <Router>
      <Layout>
        {isLoggedIn && <>
          <PptosRoutes />
          <Rpt1Routes />
          <Rpt2Routes />
          <Rpt5Routes />
          <UserRoutes />
          <NexusRoutes />
          <CFDsRoutes />
        </>}

        {isLoggedIn 
        ? <Routes>
            <Route path="glossary-entry/:id" element={<GlossaryEntry/>} />
            <Route path="create-glossary-entry" element={<CreateGlossaryEntry/>} />
            <Route path="glossary" element={<Glossary/>} />
            <Route path="/" element={<Main/>} />
            {/*<Route path="*" element={<Navigate replace to="/"/>} />*/}
          </Routes>
        : <Routes>
            <Route path="/login" element={<LoginPage />} />  
            { /* <Route path="/register" element={<RegisterPage />} />  */}
            <Route path="*" element={<Navigate replace to="/login"/>} />
          </Routes>
        }

      </Layout>
    </Router>
  </AppContext.Provider>
}

export default App;
