import fetchRequest from "../../../shared/fetchRequest";
import { Button } from "../../../shared/UI/Dumbs"

 const companies = []
 companies["001385"] = "SMETA";
 companies["002174"] = "Dineek";

const ImportCatCuentas = ({company, year, onComplete}) => {

    const getCntCat = async() => {
        const response = await fetchRequest('GET',`rpt5/cat-cuentas/inspect/${company}/${year}`)
        if(response.status === 'empty'){
            startImportation()
        }else{
            if(response?.importation?.end){
                startImportation()
            }else{
                importNextStep(response.importation)
            }
            console.log('found',response.importation)
        }
    }
    const startImportation = async() => {
        const response = await fetchRequest('GET',`rpt5/cat-cuentas/start-importation/${company}/${year}`)
        importNextStep(response.importation)
        console.log('started',response.importation)
    }

    const importNextStep = async(importation) => {
        const response = await fetchRequest('GET',`rpt5/cat-cuentas/importation-step/${company}/${year}/${importation.step}`)
        if(response?.importation?.step < response?.importation?.packages){
            importNextStep(response.importation)
        }else{
            onComplete()
        }
        console.log('step' + importation.step,response)
    }

    return <Button onClick={getCntCat} disabled={!company}>Obtener Catálogo de Cuentas {company && companies[company]} {year}</Button>
}

export default ImportCatCuentas