import { useEffect, useState } from "react"
import fetchRequest from "../../../shared/fetchRequest"
import { Button } from "../../../shared/UI/Dumbs"
import DownloadButton from "../../../shared/components/DownloadButton"

import Page from "../../../shared/UI/Page"
import UploadPpto from "../components/UploadPpto"
import SelectEmpresa from "../../../shared/components/SelectEmpresa"

const  Pptos = () => {
    const [isImporting,setIsImporting] = useState(false)
    const [disabled,setDisabled] = useState(true)
    const [year,setYear] = useState('2022')
    const [company,setCompany] = useState('')
    const [files,setFiles] = useState([])

    const reload = () => {
       alert('Upload complete')
       getFiles()
    }

    useEffect( () => {
        setDisabled( !company || !year )
    },[company, year])

    const deleteFile = async (id) => {
        if(!window.confirm("Confirmar eliminación de archivo")) return false;
        await fetchRequest('DELETE',`utils/ppto/${id}`)
        getFiles()
    }
    const importFile = async (id) => {
        setIsImporting(true)
        try {
            await fetchRequest('POST',`utils/ppto/import/${id}`)
            getFiles()
        }catch(er){ console.log(er)} 
        setIsImporting(false)
    }
    const revertImportFile = async (id) => {
        if(!window.confirm('Al revertir la importación se eliminará la información previamente importada desde este archivo')) return false;
        setIsImporting(true)
        try {
            await fetchRequest('POST',`utils/ppto/revert-import/${id}`)
            getFiles()
        }catch(er){ console.log(er)} 
        setIsImporting(false)
    }

    const getFiles = async () => {
        const {files} = await fetchRequest('GET','utils/ppto')
        setFiles(files)
    }
    useEffect( () => {
        getFiles()
    },[])

    return <Page>
        <Page.Header>
            <div>
                <h3>Presupuestos</h3>

                <SelectEmpresa value={company} onChange={setCompany} />

                <select value={year} onChange={ e => setYear(e.target.value)}>
                    <option>2022</option>
                </select>
                <br/>
                <UploadPpto disabled={disabled} company={company} year={year} onComplete={reload} />
            </div>
        </Page.Header>
        <Page.Body className="scrollable">
            <table>
                <thead>
                    <tr>
                        <th>Año</th>
                        <th>Empresa</th>
                        <th>Archivo</th>
                        <th>Tamaño</th>
                        <th>Importado</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {files.map( f => <tr key={f.id}>
                        <td>{f.anio}</td>
                        <td>{f.empresa?.name}</td>
                        <td>{f.name}</td>
                        <td className="center noWrap"><small>{f.size && formatBytes(f.size)}</small></td>
                        <td className="center"><small>{f.imported ? `${f.rows} columnas` : 'no'}</small></td>
                        <td><DownloadButton url={`utils/ppto/download/${f.id}`} filename={f.name} /></td>
                        <td><Button onClick={ () => deleteFile(f.id)}>Eliminar</Button></td>
                        <td>{f.imported ? <Button disabled={isImporting} onClick={ () => revertImportFile(f.id)}>Revertir importación</Button> : <Button disabled={isImporting} onClick={ () => importFile(f.id)}>Importar</Button>}</td>
                        <td>{f.imported && <Button link={`/presupuestos/source/${f.id}`}>Previsualizar</Button>}</td>
                    </tr>)}
                </tbody>
            </table>
        </Page.Body>
    </Page>
}

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export default Pptos