
import { useEffect, useState } from "react";
import fetchRequest from "../../../shared/fetchRequest";
import { Button } from "../../../shared/UI/Dumbs"

const Glossary = () => {
    const [entries, setEntries] = useState([])

    const getEntries = async () => {
        const {entries} = await fetchRequest('GET','glossary')
        setEntries(entries.Items)
    }

    useEffect( () => {
        getEntries()
    },[])

    return <article>
        <header>
            <h3>Glossary</h3>
            <Button link="/create-glossary-entry">Create a Glossary Entry</Button>
        </header>
        <main>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {entries.map( e => <tr key={e.id}>
                        <td>{e.id}</td>
                        <td>{e.name}</td>
                        <td>{e.description}</td>
                        <td><Button link={`/glossary-entry/${e.id}`}>Edit</Button></td>
                    </tr>) }
                </tbody>
            </table>
        </main>
    </article>
}
export default Glossary