import { Route, Routes } from "react-router-dom"
import Users from "./pages/Users"
import User from "./pages/User"
import CreateUser from "./pages/CreateUser"

const UserRoutes = () => {
    return <Routes>
        <Route path="/usuarios" element={<Users />} />
        <Route path="/usuario/:id" element={<User />} />
        <Route path="/crear-usuario" element={<CreateUser />} />
    </Routes>
}
export default UserRoutes