import { Link } from "react-router-dom";

const Card = props => {
     return <div className="card">{props.children}</div>
}
const Pre = ({value,padding}) => {
     return <pre>{JSON.stringify(value,null,padding || 2)}</pre>
}
const Button = ({children, link, type, onClick, disabled}) => {
    if(link)
        return <Link to={link}><button className="button">{children}</button></Link>
    else
        return <button disabled={disabled} onClick={onClick} type={type} className="button">{children}</button>
}

export  {
    Button, Card, Pre
};
