import { useEffect, useState } from "react"
import DownloadButton from "../../../shared/components/DownloadButton";
import SelectEmpresa from "../../../shared/components/SelectEmpresa";
import SelectMes from "../../../shared/components/SelectMes";
import SelectAnio from "../../../shared/components/SelectAnio";
import fetchRequest from "../../../shared/fetchRequest"
import { Button } from "../../../shared/UI/Dumbs"
import Page from "../../../shared/UI/Page"
import moment from 'moment'

import ImportCFDs from "../components/ImportCFDs";


let dollarUSLocale = Intl.NumberFormat('en-US',{
    style: "currency",
    currency: "USD",
});


const  Rpt1 = () => {

    const [query,setQuery] = useState(`SELECT * FROM users`)

    const pingNexus = async () => {
        const response = await fetchRequest('POST',`rpt1/nexus/query`,{query})
        console.log(response)
    }
    const [rows,setRows] = useState([])
    const [disabled,setDisabled] = useState(true)
    const [month,setMonth] = useState('01')
    const [year,setYear] = useState(moment().format('Y'))
    const [company,setCompany] = useState('')

    // const getStructure = async() => {
    //     setRows([])
    //     const {rows} = await fetchRequest('GET',`rpt1/structure/${company}/${year}/${month}`)
    // }
    const calcular = async() => {
        const response = await fetchRequest('GET',`rpt1/calcular/sierra-sat/${company}/${year}/${month}`)
        console.log(response)
    }
    const getCFDSAT = async() => {
        setRows([])
        const {rows} = await fetchRequest('GET',`rpt1/cfd-sat/${company}/${year}/${month}`)
        setRows(rows)
    }

    useEffect( () => {
        setDisabled( !company || !year || !month )
    },[company,year, month])

    return <Page>
        <Page.Header>
            <div>
                <h3>Rpt1 - Reporte comparativo SAT vs Sierra</h3>
                <ImportCFDs company={company} year={year} month={month} onComplete={()=>alert("Importación completa")} />
                {false && <div className="mb10">
                    <textarea value={query} onChange={ e => setQuery( e.target.value )} />
                    <button onClick={pingNexus}>Ping Nexus</button>
                </div>}
                <div className="mb10">
                    <SelectAnio value={year} onChange={setYear}/>
                    
                    <SelectMes value={month} onChange={setMonth}/>

                    <SelectEmpresa value={company} onChange={setCompany} />

                    <Button disabled={disabled} onClick={calcular}>Calcular</Button>
                    <Button disabled={disabled} onClick={getCFDSAT}>Previsualizar</Button>
                    <DownloadButton disabled={disabled} url={`rpt1/excel-report/${company}/${year}/${month}`} label="Descargar reporte" />
                </div>
             </div>
        </Page.Header>
        <Page.Body className="scrollable">
            {!!rows.length && <table className="card">
                <thead>
                    <tr>
                        <th></th>
                        <th>UUID</th>
                        <th colSpan={2}>Fecha</th>
                        <th colSpan={2}>Razon Social</th>
                        <th colSpan={2}>RFC</th>
                        <th colSpan={2}>Total</th>
                        <th colSpan={1}>Descripción</th>
                        
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th >Sierra</th>
                        <th >SAT</th>
                        
                        <th >Sierra</th>
                        <th >SAT</th>

                        <th >Sierra</th>
                        <th >SAT</th>

                        <th >Sierra</th>
                        <th >SAT</th>

                        <th >Sierra</th>
                        
                    </tr>
                </thead>

{ /*
      sierra_uuid:row.uuid,
            sierra_fecha:row.fecha,
            sierra_factura:row.factura,
            sierra_clave:row.clave,
            sierra_cta:row.cta,
            sierra_razonSocial:row.razonSocial,
            sierra_rfc:row.rfc,
            sierra_debe:row.debe,
            sierra_haber:row.haber,
            sierra_descripcion:row.descripcion,
            sierra:true
*/}
      
                <tbody>
                    {rows?.map( s => <tr key={s.id} >
                        <td className="noWrap">{ (!s.sierra || !s.sat) && 'Solo '+ (s.sierra ? 'Sierra' : 'SAT')}</td>
                        <td className="noWrap">{s.sierra ? s.sierra_uuid : s.sat_uuid}</td>

                        <td className="noWrap">{s.sierra_fecha}</td>
                        <td className="noWrap">{s.sat_fecha}</td>

                        <td className="noWrap">{s.sierra_razonSocial}</td>
                        <td className="noWrap">{s.sat_razonSocial}</td>
                        <td className="noWrap">{s.sierra_rfc}</td>
                        <td className="noWrap">{s.sat_rfc}</td>

                        <td className="right">{dollarUSLocale.format(s.sierra_total || 0)}</td>
                        <td className="right">{dollarUSLocale.format(s.sat_total || 0)}</td>

                        <td className="noWrap">{s.sierra_descripcion}</td>
                        

                    </tr>)}
                </tbody>
            </table>}

        </Page.Body>
    </Page>
}
export default Rpt1