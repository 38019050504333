
const Header = props => {
    return <header className="page-header">
       {props.children}
   </header>
}
const Body = props => {
    return <main className={`page-body ${props.className}`}>
       {props.children}
   </main>
}

const Page = props => {
     return <article className="page">
        {props.children}
    </article>
}
Page.Header = Header
Page.Body = Body
export default Page