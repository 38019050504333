import { useEffect, useState } from "react"

const SelectMes = ({value, onChange, disabled, style, className}) => {

    const [meses,setMeses] = useState([])

    const getMeses = async () => {
        const arrayMeses = [
            { id:'01', name:'Enero' },
            { id:'02', name:'Febrero' },
            { id:'03', name:'Marzo' },
            { id:'04', name:'Abril' },
            { id:'05', name:'Mayo' },
            { id:'06', name:'Junio' },
            { id:'07', name:'Julio' },
            { id:'08', name:'Agosto' },
            { id:'09', name:'Septiembre' },
            { id:'10', name:'Octubre' },
            { id:'11', name:'Noviembre' },
            { id:'12', name:'Diciembre' },
        ]; 

        setMeses(arrayMeses)
    }

    useEffect( () => {
        getMeses()
    },[])

    return <select 
                style={{...style}} 
                className={className || ''} 
                disabled={disabled || false} 
                value={value} 
                onChange={ e => onChange( e.target.value)}>
                    <option value="">Elige un mes</option>
                    {meses.map( mes => <option key={mes.id} value={mes.id}>{mes.name}</option>)}
            </select>
}

export default SelectMes