import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import fetchRequest from "../../../shared/fetchRequest"
import { Button, Card } from "../../../shared/UI/Dumbs"
import Page from "../../../shared/UI/Page"

const GlossaryEntry = () => {
    const navigate = useNavigate()
    
    const {id} = useParams()
    const [entry, setEntry] = useState({
        name:'',
        description:''
    })
    const deleteEntry = async () => {
        const res = await fetchRequest('DELETE','glossary/' + id)
        console.log(res)
        navigate('/glossary')
    }
    const updateEntry = async e => {
        e.preventDefault()
        const res = await fetchRequest('PUT','glossary/' + id,entry)
        console.log(res)
        navigate('/glossary')
    }

    const getEntry = useCallback(async() => {
        const res = await fetchRequest('GET','glossary/' + id)
        setEntry(res)
    },[id])

    useEffect( () => {
        getEntry()
    },[getEntry])

    return <Page>
        <Page.Header>
                Glossary Entry
            <Button link="/glossary">back</Button>
        </Page.Header>
        <Page.Body>
            <Card>
                <form onSubmit={updateEntry}>
                    <label>
                        Name:
                        <input placeholder="Name" value={entry.name} onChange={ e => setEntry( state => ({...state, name: e.target.value}))}  />
                    </label>
                    <label>
                        Description:
                        <textarea placeholder="Description" value={entry.description} onChange={ e => setEntry( state => ({...state, description: e.target.value}))}  />
                    </label>
                    <Button type="submit">Update Entry</Button>
                </form>
            </Card>

            <Card>
                <Button type="button" onClick={deleteEntry}>Delete</Button>
            </Card>
        </Page.Body>
    </Page>
}
export default GlossaryEntry