import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "../shared/context/AppContext";

const Menu = () => {

    const {user} = useContext(AppContext)

    return <aside className="menu">
        <NavLink to="/">Home</NavLink>
        
        <hr/>
        <h5 className="mb10">Reportes</h5>
        <NavLink to="/rpt1">Comparativo SAT-Sierra</NavLink>
        <NavLink to="/rpt2">Comparativo Nexus-Sierra</NavLink>
        <NavLink to="/rpt5">Reporte de variaciones</NavLink>

        <>
            <h5 className="mb10">Utilerías</h5>
            <NavLink to="/cfds">CFDs (SAT)</NavLink>
            {user?.isAdmin && <NavLink to="/presupuestos">Presupuestos</NavLink>}
            {user?.isDev &&<NavLink to="/nexus">Nexus App</NavLink>}
        </>
        {user?.isDev && <>
            <h5 className="mb10">En Desarrollo</h5>
            
        </>}
        {user?.isAdmin && <>
            <h5 className="mb10">Administrator</h5>
            <NavLink to="/usuarios">Usuarios</NavLink>
        </>}
        

    </aside>
}

export default Menu;