import { useCallback, useEffect, useState } from "react"
import * as moment from 'moment'
//import { useCallback } from "react/cjs/react.production.min"

const SelectAnio = ({value, onChange, disabled, style, className, anioInicial = 2019}) => {
    const [anios,setAnios] = useState([])

    const getAnios = useCallback( async () => {
        const anios = [];
        const anioFinal = moment().format("YYYY") 

        for (let anio = anioInicial; anio <= anioFinal; anio++) {
            anios.push(anio)
        }
       
        setAnios(anios)
    },[anioInicial])

    useEffect( () => {
        getAnios()
    },[getAnios])

    return <select 
                style={{...style}} 
                className={className || ''} 
                disabled={disabled || false} 
                value={value} 
                onChange={ e => onChange( e.target.value)}>
                    <option value="">Elige un año</option>
                    {anios.map( anio => <option key={anio} value={anio}>{anio}</option>)}
            </select>
}
export default SelectAnio