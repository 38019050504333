import { useEffect, useState } from "react"
import fetchRequest from "../../../shared/fetchRequest"
import { Button } from "../../../shared/UI/Dumbs"
import DownloadButton from "../../../shared/components/DownloadButton"

import Page from "../../../shared/UI/Page"
import UploadNexus from "../components/UploadNexus"

const  Nexus = () => {

    const [files,setFiles] = useState([])

    const reload = () => {
       alert('Upload complete')
       getFiles()
    }

    const deleteFile = async (id) => {
        if(!window.confirm("Confirmar eliminación de archivo")) return false;
        await fetchRequest('DELETE',`utils/nexus/${id}`)
        getFiles()
    }

    const getFiles = async () => {
        const {files} = await fetchRequest('GET','utils/nexus')
        setFiles(files)
    }
    useEffect( () => {
        getFiles()
    },[])

    return <Page>
        <Page.Header>
            <div>
                <h3>Nexus</h3>
                <UploadNexus onComplete={reload} />
            </div>
        </Page.Header>
        <Page.Body className="scrollable">
            <table>
                <thead>
                    <tr>
                        <th>Archivo</th>
                        <th>Tamaño</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {files.map( f => <tr key={f.id}>
                        <td>{f.name}</td>
                        <td className="center noWrap"><small>{f.size && formatBytes(f.size)}</small></td>
                        <td><DownloadButton url={`utils/nexus/download/${f.id}`} filename={f.name} /></td>
                        <td><Button onClick={ () => deleteFile(f.id)}>Eliminar</Button></td>
                    </tr>)}
                </tbody>
            </table>
        </Page.Body>
    </Page>
}

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export default Nexus