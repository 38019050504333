import { useEffect, useState } from "react"
import fetchRequest from "../fetchRequest"

const SelectEmpresa = ({value, onChange, disabled, style, className}) => {

    const [empresas,setEmpresas] = useState([])

    const getEmpresas = async () => {
        const {empresas} = await fetchRequest('GET','shared/empresas')
        setEmpresas(empresas)
    }

    useEffect( () => {
        getEmpresas()
    },[])

    return <select 
                style={{...style}} 
                className={className || ''} 
                disabled={disabled || false} 
                value={value} 
                onChange={ e => onChange( e.target.value)}>

                    <option value="">Elige una empresa</option>
                    {empresas.map( empresa => <option key={empresa.id} value={empresa.id}>{empresa.name}</option>)}
            </select>
}

export default SelectEmpresa