import { useEffect, useState } from "react"
import fetchRequest from "../../../shared/fetchRequest"
import { Button } from "../../../shared/UI/Dumbs"
import DownloadButton from "../../../shared/components/DownloadButton"

import Page from "../../../shared/UI/Page"
import UploadCFD from "../components/UploadCFD"

const  CFDs = () => {

    const [isImporting,SetIsImporting] = useState(false)
    const [disabled,setDisabled] = useState(true)
    const [month,setMonth] = useState('01')
    const [year,setYear] = useState('2022')
    const [company,setCompany] = useState('')
    const [empresas,setEmpresas] = useState([])
    const [files,setFiles] = useState([])

    const reload = () => {
       alert('Upload complete')
       getFiles()
    }

    useEffect( () => {
        setDisabled( !company || !year )
    },[company, year])

    const deleteFile = async (id) => {
        if(!window.confirm("Confirmar eliminación de archivo")) return false;
        await fetchRequest('DELETE',`utils/cfd/${id}`)
        getFiles()
    }
    const importFile = async (id) => {
        SetIsImporting(true)
        try {
            await fetchRequest('POST',`utils/cfd/import/${id}`)
            getFiles()
            SetIsImporting(false)
        }catch(er){ 
            console.log(er)
            alert('La importación tomara mas de 30 segundos, espera un minuto y actualiza la pagina')
        } 
    }

    const getEmpresas = async () => {
        const {empresas} = await fetchRequest('GET','utils/ppto/empresas')
        setEmpresas(empresas)
    }
    const getFiles = async () => {
        const {files} = await fetchRequest('GET','utils/cfd')
        setFiles(files)
    }
    useEffect( () => {
        getEmpresas()
        getFiles()
    },[])

    return <Page>
        <Page.Header>
            <div>
                <h3>CFDs (SAT)</h3>
                <select value={company} onChange={ e => setCompany(e.target.value)}>
                    <option value="">Elige una empresa</option>
                    {empresas.map( c => <option key={c.id} value={c.id}>{c.name}</option>)}
                </select>
                <select value={year} onChange={ e => setYear(e.target.value)}>
                    <option>2020</option>
                    <option>2021</option>
                    <option>2022</option>
                </select>
                <select value={month} onChange={ e => setMonth(e.target.value)}>
                    <option value="01">Enero</option>
                </select>
                
                <br/>
                <UploadCFD disabled={disabled} company={company} year={year} month={month} onComplete={reload} />
            </div>
        </Page.Header>
        <Page.Body className="scrollable">
            <table>
                <thead>
                    <tr>
                        <th>Año</th>
                        <th>Mes</th>
                        <th>Empresa</th>
                        <th>Archivo</th>
                        <th>Tamaño</th>
                        <th>Importado</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {files.map( f => <tr key={f.id}>
                        <td>{f.anio}</td>
                        <td>{f.mes}</td>
                        <td>{f.empresa?.name}</td>
                        <td>{f.name}</td>
                        <td className="center noWrap"><small>{f.size && formatBytes(f.size)}</small></td>
                        <td className="center"><small>{f.imported ? `${f.rows} columnas` : 'no'}</small></td>
                        <td><DownloadButton url={`utils/cfd/download/${f.id}`} filename={f.name} /></td>
                        <td><Button onClick={ () => deleteFile(f.id)}>Eliminar</Button></td>
                        <td>{!f.imported && <Button disabled={isImporting} onClick={ () => importFile(f.id)}>Importar</Button>}</td>
                    </tr>)}
                </tbody>
            </table>
        </Page.Body>
    </Page>
}

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export default CFDs