
import { Card } from "../../shared/UI/Dumbs";
import Page from "../../shared/UI/Page";

const Main = () => {
    /*const [recordset,setRecordset] = useState([])
    const testSQL = async () => {
        const {recordset} = await fetchRequest('GET','/sql')
        setRecordset(recordset)

      }
      
     <Pre value={recordset} />
          { process.env.REACT_APP_ENV || '?'}
          <Button onClick={testSQL}>test SQL</Button>
          */
  const fullReload = () => {
    window.location.reload();
  }


  
  return <Page>
    <Page.Header>
        <h3>Home</h3>
    </Page.Header>
    <Page.Body>
        <Card>
          <button onClick={fullReload}>Refresh Page</button>
        </Card>
    </Page.Body>
  </Page>


}
export default Main