import { useContext, useState } from "react"
import { AppContext } from "../../../shared/context/AppContext"
import fetchRequest from "../../../shared/fetchRequest"

import './LoginPage.scss'

const LoginPage = () => {
    const app = useContext(AppContext)

    const [user,setUser] = useState({
        password:'',
        email:''
    })
    const tryLogin = async e => {
        e.preventDefault()
        try {
          const data = await fetchRequest('POST','auth/login',user)
          app.login(data)

        }catch(er){
          console.error('error',er)
          alert(er.message)
        }
        
        /*try{
            const fetchResponse = await fetch(window.api_url + 'auth/login',{
                method: 'POST',
                body:JSON.stringify(user),
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
            })
            if(fetchResponse.ok){
              const data = await fetchResponse.json()
              app.login(data)
            }
          }catch(er){
            console.log(er)
          }*/
    }

   /* const getProducts = async() => {
        try{
          const fetchResponse = await fetch(window.api_url + 'users',{
            method: 'POST'
          })
          if(fetchResponse.ok){
            const data = await fetchResponse.text()
            console.log(data)
          }
        }catch(er){
          console.log(er)
        }
      }
    
      useEffect( () => getProducts(), [])*/

    return <div className="login-page">
    <form onSubmit={tryLogin} className="card login-form">
        <label>
          Email
          <input placeholder="email" type="email" value={user.email} onChange={ e => setUser( state => ({...state, email: e.target.value}) )} />
        </label>

        <label>
          Password
          <input placeholder="password" type="password" value={user.password} onChange={ e => setUser( state => ({...state, password: e.target.value}) )} />
        </label>

        <button type="submit">Log In</button>
    </form>
</div>
}

export default LoginPage;