import { useState } from "react";
import fetchRequest from "../fetchRequest";


const DownloadButton = ({url, label, disabled}) => {
    const [title, setTitle] = useState(label || 'Descargar')

    const download = async () => {

        setTitle('Descargando...')

        try {
            const response = await fetchRequest('GET', url)
            setTitle('Listo')
            const buff = Buffer(response.buffer,'base64');
            var blob = new Blob([buff], {'type': response.type});
            var downloadURL = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadURL;
            a.download = response.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a)
        }catch(er){ console.log(er)} 

        setTitle(label || 'Descargar')
        
    }
    return <button disabled={disabled} onClick={download}>{title}</button>
}
export default DownloadButton;