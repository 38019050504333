import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import fetchRequest from "../../../shared/fetchRequest";

const CreateUser = () => {
    const navigate = useNavigate()
    const [user, setUser] = useState({
        name:'',
        email:'',
        password:''
    })

    const createUser = async e => {
        e.preventDefault()
        try {
            await fetchRequest('POST','auth/register',user)
            navigate('/usuarios')
        }catch(er){ console.log(er)} 
    }
    return <div className="page">
        <header>
            <h4>Create User</h4>
            <Link to="/usuarios">{`< back`}</Link>
        </header>
        <main className="scrollable">
            <div className="card">
                <form onSubmit={createUser}>
                    <label>
                        Name:
                        <input value={user.name} onChange={ e => setUser({...user, name: e.target.value})} />
                    </label>
                    <label>
                        Email:
                        <input type="email" value={user.email} onChange={ e => setUser({...user, email: e.target.value})} />
                    </label>
                    <label>
                        Password:
                        <input type="password" value={user.password} onChange={ e => setUser({...user, password: e.target.value})} />
                    </label>
                    <button type="submit">Create User</button>
                </form>
            </div>
        </main>
    </div>
}
export default CreateUser;