import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import fetchRequest from "../../../shared/fetchRequest"

const Users = () => {
    const [users, setUsers] = useState([])
    
    const getUsers = async() => {
        const {users} = await fetchRequest('GET','users');
        setUsers(users)
    }

    useEffect( () => {
        getUsers()
    },[])
    return <div className="page">
        <header>
            <h3>Usuarios</h3>
            <Link to="/crear-usuario"><button className="blue">+ Crear Usuario</button></Link>
        </header>
        <main className="scrollable">
            
            <table className="card">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Correo electrónico</th>
                        <th>Administrador</th>
                        <th>Developer</th>
                        <th>Fecha de creación</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {users.map( user => <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td className="center">{user.isAdmin ? 'si' : 'no'}</td>
                    <td className="center">{user.isDev ? 'si' : 'no'}</td>
                    <td>{user.createdAt && moment(user.createdAt).format('DD/MMM/Y')}</td>
                    <td><Link to={`/usuario/${user.id}`}><button>edit</button></Link></td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default Users